<template>
  <div>
    <div class="custom-radio">
      <input type="radio" id="yes" value="true" class="hidden-radio" :checked="picked === 1">
      <label for="yes" class="custom-radio-label" @click.stop="setPicked(1)">Yes</label>
    </div>
    <div class="custom-radio">
      <input type="radio" id="no" value="false" class="hidden-radio" :checked="picked === 2">
      <label for="no" class="custom-radio-label" @click.stop="setPicked(2)">No</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Communication',
  data() {
    return {
      picked: this.offer.communicate,
    };
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setPicked(predicate) {
      this.picked = predicate;
      this.$emit('changeCommunication', {
        offerId: this.offer.id,
        value: this.picked,
      });
    },
  },
};
</script>

<style scoped>
  .hidden-radio {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
  }

  .custom-radio {
    display: inline-block;
    cursor: pointer;
  }

  .custom-radio-label {
    padding: 10px 15px;
    border: 1px solid #ddd;
    background-color: #EFEFEF;
    border-radius: 10px;
    margin-right: 5px;
    display: inline-block;
    cursor: pointer;
  }

  .custom-radio-label:hover {
    background-color: #ddd;
  }

  input[type="radio"]:checked + .custom-radio-label {
    background-color: #359D5F;
    color: white;
  }
</style>

<template>
  <div class="accFeed__wrapper matchmaking__wrapper">
    <div class="accFeed__sidebar matchmaking__sidebar">
      <div class="accFeed__box matchmaking__box">
        <span
          class="btn btn-primary w-100"
          @click="$router.push('/profile/matchmaking/seller')">Project search
        </span>
      </div>
    </div>
    <div class="project-details">
      <div class="offers">
        <div class="d-flex justify-content-center p-2" v-if="isLoading">
          <div class="loader"></div>
        </div>
        <div v-else>
          <div class="offers-status" v-if="data">
            <h3>You have:
              <strong>{{ summaryOffersLength }}</strong> offers;
              <strong>{{ data.projects.length }}</strong> saved projects
            </h3>
            <div class="offers-status__filters">
              <a>
                <span class="status all" :class="{active: activeFilter === 'all'}"
                  @click="activeFilter = 'all'">
                  All
                </span>
              </a>
              <span v-for="(values, key) in data" :key="key">
                <span class="status" :class="[key, { active: activeFilter === key }]"
                  @click="activeFilter = key">
                  <span v-if="key === 'projects'">
                    saved
                  </span>
                  <span v-else>
                    {{ key }}
                  </span>
                </span>
              </span>
            </div>
          </div>
          <p
            v-if="!me.safe_seller"
            class="draft-note"
            style="text-align: left;">
            Please <a class="link" href="mailto:info@wethink.eu">
            contact us</a> in order to make your Offers visible to Buyers!
          </p>
          <template v-if="activeFilter === 'all'">
            <template v-if="filteredOffers.filter((item) => item[1].length)">
              <h5>My Offers</h5>
              <div class="accordion offers__box" id="accordionOffers">
                <div v-for="item in filteredOffers" :key="item[0]">
                  <div v-for="(offer, index) in item[1]" :key="offer.id">
                    <OfferCard
                      :type="item[0]"
                      :offer="offer"
                      :index="index"
                      @sendMessage="sendMessage"
                      @sellerDeleteOffer="sellerDeleteOffer"
                      @sellerUpdateOffer="sellerUpdateOffer"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template v-if="data.projects.length">
              <h5>My Saved Projects</h5>
              <div class="market-project">
                <div class="market-project__list">
                  <div v-for="(item, index) in data.projects" :key="index">
                    <SavedProject v-if="item"
                    :data="item"
                    @sellerCreateOffer="sellerCreateOffer"
                    @sellerUpdateOffer="sellerUpdateOffer"
                    @sellerSaveProject="sellerSaveProject"
                    @sellerUnsaveProject="sellerUnsaveProject"/>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-else-if="activeFilter !== 'projects'">
            <template v-if="Object.values(filteredOffers)[0] &&
              Object.values(filteredOffers)[0][1].length">
              <h5>My Offers</h5>
              <div class="accordion offers__box" id="accordionOffers">
                <div v-for="item in Object.values(filteredOffers)" :key="item[0]">
                  <div v-for="(offer, index) in item[1]" :key="offer.id">
                    <OfferCard
                      :type="item[0]"
                      :offer="offer"
                      :index="index"
                      @sendMessage="sendMessage"
                      @sellerDeleteOffer="sellerDeleteOffer"
                      @sellerUpdateOffer="sellerUpdateOffer"
                    />
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <template v-if="data.projects.length">
              <h5>My Saved Projects</h5>
              <div class="market-project">
                <div class="market-project__list">
                  <div v-for="(item, index) in data.projects" :key="index">
                    <SavedProject v-if="item"
                    :data="item"
                    @sellerCreateOffer="sellerCreateOffer"
                    @sellerUpdateOffer="sellerUpdateOffer"
                    @sellerSaveProject="sellerSaveProject"
                    @sellerUnsaveProject="sellerUnsaveProject"/>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import exception from '@/mixins/exception';
import MatchmakingService from '@/services/MatchmakingService';
import OfferCard from '@/views/profile/Matchmaking/Seller/OfferCard.vue';
import SavedProject from '@/views/profile/Matchmaking/Seller/SavedProject.vue';

export default {
  name: 'Offers',
  components: { OfferCard, SavedProject },
  mixins: [exception],
  data() {
    return {
      isLoading: false,
      activeFilter: 'all',
      data: {
        offered: [],
        projects: [],
        draft: [],
        approved: [],
        declined: [],
      },
    };
  },
  async mounted() {
    const typeParam = this.$route.query.type;
    if (typeParam) {
      this.activeFilter = typeParam;
      const { query, ...routeWithoutType } = this.$route;
      this.$router.replace(
        { ...routeWithoutType, query: { ...query, type: undefined } },
      );
    }
    this.loadMarketplaceStatistics();
  },
  methods: {
    async loadMarketplaceStatistics() {
      try {
        this.$store.commit('SET_IS_LOADING', true);
        this.isLoading = true;
        const { data } = await MatchmakingService.marketplacesStatistics(false);
        this.data = data;
      } catch (e) {
        this.exception(e);
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
        this.isLoading = false;
      }
    },
    async sendMessage(messageData) {
      const { offerId, text, type } = messageData;
      if (text) {
        try {
          const payload = {
            offer_id: offerId,
            text,
          };
          const { data } = await MatchmakingService.marketplaceOfferMessage(payload);
          this.data[type].forEach((el) => {
            if (el.id === Number(offerId)) {
              el.messages.push(data);
            }
          });
        } catch (error) {
          this.handleException(error);
        }
      }
    },
    async sellerUpdateOffer() {
      await this.loadMarketplaceStatistics();
    },
    async sellerCreateOffer() {
      await this.loadMarketplaceStatistics();
    },
    async sellerDeleteOffer(offerId) {
      try {
        await MatchmakingService.deleteOffer(offerId);
        await this.loadMarketplaceStatistics();
      } catch (error) {
        this.handleException(error);
      }
    },
    async sellerSaveProject(projectId) {
      try {
        await MatchmakingService.sellerSaveProject({ project_id: projectId });
        const index = this.data.projects.findIndex((el) => el.id === Number(projectId));
        if (index !== -1) {
          this.data.projects[index].saved = true;
        }
      } catch (error) {
        this.handleException(error);
      }
    },
    async sellerUnsaveProject(projectId) {
      try {
        await MatchmakingService.sellerUnsaveProject({ project_id: projectId });
        const index = this.data.projects.findIndex((el) => el.id === Number(projectId));
        if (index !== -1) {
          this.data.projects[index].saved = false;
        }
      } catch (error) {
        this.handleException(error);
      }
    },
  },
  computed: {
    summaryOffersLength() {
      let length = 0;
      Object.entries(this.data).forEach((data) => {
        if (data[0] !== 'projects') {
          length += data[1].length;
        }
      });
      return length;
    },
    filteredOffers() {
      /* eslint-disable */
      return Object.entries(this.data).filter((data) => {
        if (data[0] !== 'projects' && this.activeFilter !== 'projects') {
          if (this.activeFilter === 'all') {
            return true;
          } else {
            return data[0] === this.activeFilter;
          }
        }
      });
    },
    me() {
      return this.$store.getters.getMe;
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <div>
    <div class="card">
      <span v-if="unreadMessagesCount" class="message_badge">
        {{unreadMessagesCount}}
      </span>
      <BModal
        data-id="modal-request-offer"
        ref="modalRequestOffer"
        if="modalRequestOffer"
        class="modal-request-offer"
        size="xl"
        hide-header
        hide-footer>
        <div class="body">
          <OfferFormRequest
          :marketplaceId="offer.marketplace.id"
          :formId="formId"
          :isNewOffer="false"
          :projectId="offer.project.id"
          :offer="offer"
          @onDeleteOffer="onDeleteOffer"
          @sendMessage="sendNewMessage"
          @onUpdateOffer="onUpdateOffer"
          @onCreatedOffer="onCreatedOffer"
          @close="closeModal"
          />
        </div>
      </BModal>
      <div class="card-header" :id="`headingOffers-${offer.id}`">
        <button class="btn-accordion collapsed" :class="type" type="button"
        data-toggle="collapse" :data-target="`#collapse-offer${offer.id}`"
        aria-expanded="false" :aria-controls="`collapse-offer${offer.id}`"
          @click="markOfferAsRead">
          <div :class="{'card-header__box': isBuyer === true}">
            <ul class="offers__header">
              <li><label>Project:</label>
                <strong>
                  <span class="link" @click.prevent.stop="openProject(offer.project.short_name)">
                    {{ offer.project.name }}
                  </span>
                </strong>
              </li>
              <li><label>Title:</label><strong>{{ offer.name }}</strong></li>
              <li><label>Price:</label><strong>{{ offer.price }} euro</strong></li>
              <li><label>Delivery time:</label><strong>{{ offer.delivery_at }}</strong></li>
              <li v-if="!isBuyer">
                <label>Status:</label>
                <span class="status" :class="offerStatus">
                  {{ offerStatus }}
                </span>
              </li>
              <li v-else>
                <label>Company name:</label>
                <strong>{{ offer.company_name }}</strong>
              </li>
            </ul>
            <template v-if="!isBuyer">
              <a v-if="offerStatus === 'offered'"
                class="edit-offer" @click="makeAnOffer($event)">
                <i class="icon-edit-offer"></i>
                Edit offer
              </a>
              <a v-else-if="offerStatus === 'draft'"
                class="edit-offer" @click="makeAnOffer($event)">
                Finalize draft
              </a>
            </template>
            <template v-else>
              <ul class="offers-action">
                <template v-if="offerStatus === 'approved'"></template>
                <template v-else-if="offerStatus === 'declined'">
                  <li @click.stop="changeStatus(5)">
                    <a class="btn btn-reject">
                      Consider again
                    </a>
                  </li>
                </template>
                <template v-else>
                  <template v-if="visible === 1">
                    <li>
                      <a class="btn btn-hide" @click.stop="changeVisible(2)">
                        Hide project
                      </a>
                    </li>
                    <li>
                      <a class="btn btn-approve" @click.stop="changeStatus(3)">
                        Approve
                      </a>
                    </li>
                    <li>
                      <a class="btn btn-reject" @click.stop="changeStatus(4)">
                        Reject
                      </a>
                    </li>
                  </template>
                  <template v-else>
                    <li>
                      <a class="btn btn-hide" @click.stop="changeVisible(1)">
                        Show project
                      </a>
                    </li>
                  </template>
                </template>
              </ul>
            </template>
          </div>
        </button>
      </div>
    </div>
    <div :id="`collapse-offer${offer.id}`" class="collapse"
      :aria-labelledby="`headingOffers-${offer.id}`" data-parent="">
      <div class="card-body">
        <div class="form-line mb-2" v-if="isBuyer && offer.project.status !== 4">
          <label>Enable communication with seller even after deadline is over?</label>
          <Communication :offer="offer" @changeCommunication="changeCommunication"/>
        </div>
        <h5>Company details seller:</h5>
        <p>{{ offer.company_detail }}</p>
        <h5>Scope of work: </h5>
        <p>{{ offer.scope_work }}</p>
        <div class="addmedia" v-if="documents.length || images.length || video.length">
            <BModal v-model="showPreview"
            hide-footer
            hide-header
            size="lg"
            @close="hidePreviewModal">
              <div class="modal-image">
                <div class="modal-image-content">
                  <span class="close" @click="hidePreviewModal">&times;</span>
                  <img :src="selectedImage" alt="" style="max-width: 80%;"
                    v-if="selectedImage">
                  <iframe :src="selectedPdf" frameborder="0" style="height: 80vh; width: 100%;"
                    v-else/>
                </div>
              </div>
            </BModal>
            <div class="addmedia__downloaded">
              <h5 class="addmedia__downloaded-title" style="font-weight: 600;">
                Presentation and documentation
              </h5>
              <div v-if="documents.length">
                <h6>Documents ({{ documents.length }}):</h6>
                <div class="addmedia__downloaded-item"
                  v-for="item in documents" :key="item.url">
                    <span class="icon-pdf"></span>
                    <span class="pointer"
                    @click="showPreviewModal(item.url, 'pdf')">
                      {{ item.title }}
                    </span>
                  </div>
              </div>
              <div v-if="images.length">
                <h6>Images ({{ images.length }}):</h6>
                <ul class="media-list">
                  <li class="media-item" v-for="item in images" :key="item.url">
                    <img :src="item.url" alt="" class="pointer"
                    @click="showPreviewModal(item.url, 'img')">
                    <span>{{ item.title }}</span>
                  </li>
                </ul>
              </div>
              <div v-if="video.length">
                <h6>Video ({{  video.length }}):</h6>
                <ul class="media-list">
                  <li class="media-item" v-for="item in video" :key="item.thumbnail">
                    <div v-html="item.html"></div>
                    <span>{{ item.title }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="discussion">
            <div class="messages__wapper"
            v-for="message in offer.messages" :key="message.id">
            <OfferMessage
            :message="message"
            :sellerEmail="offer.user.email"
            />
          </div>
          <div v-if="offer.project.status === 1 || type === 'approved'
            || (offer.project.status === 4 && offer.communicate === 1)"
          class="discussion__body">
            <div class="discussion__comment">
              <div class="discussion__avatar">
                <img v-if="me.avatar" :src="me.avatar" alt="">
                <img v-else src="@/assets/images/avatar.svg" alt="">
              </div>
              <div class="discussion__form">
                <textarea class="growTextarea" name="" row="1" id=""
                placeholder="Leave your message..."
                style="height: 54px;" v-model="message"></textarea>
                <button class="discussion__btn" @click="sendMessage($event)">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import OfferMessage from '@/views/profile/Matchmaking/Seller/OfferMessage.vue';
import OfferFormRequest from '@/components/matchmaking/OfferFormRequest.vue';
import Communication from '@/components/matchmaking/Communication.vue';
import MatchmakingService from '@/services/MatchmakingService';
import exception from '@/mixins/exception';

export default {
  name: 'OfferCard',
  mixins: [exception],
  data() {
    return {
      showPreview: false,
      message: '',
      selectedImage: '',
      selectedPdf: '',
      formId: '',
      enable_communication: 0,
      visible: 2,
      counter: 0,
      unreadMessagesCount: 0,
    };
  },
  components: {
    BModal, OfferMessage, OfferFormRequest, Communication,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async changeCommunication(data) {
      this.counter += 1;
      const { offerId, value } = data;
      try {
        const payload = {
          offer_id: String(offerId),
          status: 'communicate',
          value,
        };
        const response = await MatchmakingService.changeOfferStatus(payload);
        console.log('response', response);
      } catch (error) {
        console.log('error', error);
      }
    },
    async changeVisible(value) {
      try {
        const payload = {
          offer_id: String(this.offer.id),
          status: 'visible',
          value,
        };
        const response = await MatchmakingService.changeOfferStatus(payload);
        this.visible = value;
        console.log('response', response);
      } catch (error) {
        console.log('error', error);
      }
    },
    async changeStatus(value) {
      try {
        const payload = {
          offer_id: String(this.offer.id),
          status: 'status',
          value,
        };
        await MatchmakingService.changeOfferStatus(payload);
        this.$emit('changeStatus', value);
      } catch (error) {
        console.log('error', error);
      }
    },
    async markOfferAsRead() {
      await MatchmakingService.getOffer(this.offer.id);
    },
    onDeleteOffer() {
      this.$emit('sellerDeleteOffer', this.offer.id);
    },
    sendNewMessage(messageData) {
      this.$emit('sendMessage', messageData);
    },
    sendMessage(event) {
      event.preventDefault();
      event.stopPropagation();
      this.sendNewMessage({
        offerId: String(this.offer.id),
        text: this.message,
        type: this.type,
      });
      this.message = '';
    },
    showPreviewModal(url, type) {
      this.selectedImage = '';
      this.selectedPdf = '';
      if (type === 'img') {
        this.selectedImage = url;
      } else {
        this.selectedPdf = url;
      }
      this.showPreview = true;
    },
    hidePreviewModal() {
      this.showPreview = false;
    },
    generateFormId() {
      this.formId = Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15);
    },
    closeModal() {
      this.$refs.modalRequestOffer.hide();
    },
    makeAnOffer(event) {
      event.preventDefault();
      event.stopPropagation();
      this.generateFormId();
      this.$refs.modalRequestOffer.show();
    },
    onCreatedOffer() {
      this.$emit('sellerCreateOffer');
      this.closeModal();
    },
    onUpdateOffer() {
      this.$emit('sellerUpdateOffer');
      this.closeModal();
    },
    openProject(projectId) {
      window.open(`/profile/matchmaking/${this.isBuyer ? 'buyer' : 'seller'}/project/${projectId}`, '_blank');
    },
  },
  computed: {
    offerStatus() {
      return String(this.offer.status.filter((status) => status.active)[0].name)
        .toLocaleLowerCase();
    },
    images() {
      if (this.offer.documents) {
        return this.offer.documents.filter((doc) => !doc.url.includes('pdf'));
      }
      return [];
    },
    documents() {
      if (this.offer.documents) {
        return this.offer.documents.filter((doc) => doc.url.includes('pdf'));
      }
      return [];
    },
    video() {
      if (this.offer.video) {
        return this.offer.video;
      }
      return [];
    },
    isBuyer() {
      return this.$store.getters.isBuyer;
    },
    me() {
      return this.$store.getters.getMe;
    },
  },
  async created() {
    const type = this.isBuyer ? 'buyer' : 'seller';
    this.visible = this.offer.visible;
    this.unreadMessagesCount = !this.isBuyer ? this.$store.getters
      .getUnreadMarketplaceData?.[type]?.messages?.[this.offer.id] || 0
      : this.$store.getters.getUnreadMarketplaceData?.[type]?.offers?.[this.offer.id] || 0;
  },
};
</script>

<style lang="scss" scoped>
.link {
  color: #007bff;
  cursor: pointer;
  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
}
.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute;
  top: 5px;
  left: 5px;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  @media (max-width: 992px) {
    left: 10px;
  }
}
</style>

<template>
  <div>
    <div class="market-project__item">
      <BModal
        data-id="modal-request-offer"
        ref="modalRequestOffer"
        if="modalRequestOffer"
        class="modal-request-offer"
        size="xl"
        hide-header
        hide-footer>
        <div class="body">
          <OfferFormRequest
          :marketplaceId="data.marketplace.id"
          :formId="formId"
          :isNewOffer="true"
          :projectId="selectedProjectId"
          @sendMessage="sendMessage"
          @onUpdateOffer="onUpdateOffer"
          @onCreatedOffer="onCreatedOffer"
          @onSaveOffer="onSaveOffer"
          @close="closeModal"
          />
        </div>
      </BModal>
      <!-- data: {{ data }} -->
      <div class="market-project__content">
        <h3 class="market-project__title">
          <a>
            {{ data.name }}
          </a>
        </h3>
        <ul class="market-project__category">
          <li>
            <span>Country:
              <strong>{{ data.company_country.name }}</strong>
            </span>
          </li>
          <div v-for="(category, _index) in data.data" :key="_index" class="d-flex">
            <div v-if="category" class="d-flex">
              <div v-if="category.type === 'dropbox'" class="d-flex">
                <div v-if="!category.multiple && category.initValue" class="d-flex">
                  <li :id="`${data.name}-${category.name}`" style="cursor: default;">
                    <span>{{ category.name }}:
                      <strong>
                        {{ category.values[category.initValue] }}
                      </strong>
                    </span>
                  </li>
                </div>
                <div v-else class="d-flex">
                    <div v-for="(value, idx) in category.initValue" :key="idx" class="d-flex">
                      <li :id="`${data.name}-${category.name}`" style="cursor: default;">
                        <span>{{ category.name }}:
                          <strong>{{ category.values[value] }}</strong>
                        </span>
                      </li>
                    </div>
                </div>
              </div>
              <div v-else>
                <li v-if="category.initValue" :id="`${data.name}-${category.name}`"
                  style="cursor: default;">
                  <span>{{ category.name }}:
                    <strong>{{ category.initValue }}</strong>
                  </span>
                </li>
              </div>
            </div>
          </div>
        </ul>
      </div>
      <div class="market-project__actions">
        <div class="market-project__info">
          <span v-if="data.saved" class="status saved">
            Saved
          </span>
          <span class="deadline-info">
            <i class="icon-calendar"></i>Offer deadline:
            <strong>{{ formattedDeadline(data.deadline_at) }}</strong>
          </span>
          <span v-if="data.offers_count === 1">
            <strong>{{ data.offers_count }}</strong> offer from Seller
          </span>
          <span v-else-if="data.offers_count > 1">
            <strong>{{ data.offers_count }}</strong> offers from Sellers
          </span>
          <span v-else>No offers yet</span>
        </div>
        <div class="market-project__buttons">
          <a v-if="!data.saved" @click="sellerSaveProject(data.id)"
            class="btn-action">Save</a>
          <a v-else @click="sellerUnsaveProject(data.id)"
            class="btn-action">Unsave</a>
          <a v-if="data.offers_allow === 1 && data.status !== 2"
            class="btn-action"
            @click="makeAnOffer(data.id)"
            >Make an Offer
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OfferFormRequest from '@/components/matchmaking/OfferFormRequest.vue';
import { BModal } from 'bootstrap-vue';
import exception from '@/mixins/exception';

export default {
  name: 'SavedProject',
  mixins: [exception],
  components: { BModal, OfferFormRequest },
  data() {
    return {
      formId: '',
      selectedProjectId: 0,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    sellerSaveProject(id) {
      this.$emit('sellerSaveProject', id);
    },
    sellerUnsaveProject(id) {
      this.$emit('sellerUnsaveProject', id);
    },
    onCreatedOffer() {
      this.$emit('sellerCreateOffer');
      this.closeModal();
    },
    onUpdateOffer() {
      this.$emit('sellerUpdateOffer');
      this.closeModal();
    },
    onSaveOffer(data) {
      console.log(data, ' data');
      this.$emit('sellerSaveOffer', data);
      this.closeModal();
    },
    generateFormId() {
      this.formId = Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15);
    },
    closeModal() {
      this.selectedProjectId = 0;
      this.$refs.modalRequestOffer.hide();
    },
    makeAnOffer(projectId) {
      this.selectedProjectId = projectId;
      this.generateFormId();
      this.$refs.modalRequestOffer.show();
    },
    sendMessage(messageData) {
      console.log(messageData, ' messageData');
      //
    },
  },
  computed: {
    formattedDeadline() {
      return (deadline) => {
        if (deadline === '∞') {
          return 'not specified';
        }
        return deadline;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
